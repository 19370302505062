<template>
  <section class="mt-10">
    <v-row class="mx-10">
      <v-col cols="6">
        <v-card>
          <v-row class="px-10 py-10">
            <v-col cols="6">
              <h1 v-if="selectedBus">Автобус: {{ selectedBus.name }}, {{selectedBus.busStateID}}</h1>
            </v-col>
            <v-col cols="6">
              <v-row justify="end">
                <v-btn
                  @click="_addStudentToLesson"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  class="
                    font-weight-bold
                    text-capitalize
                    ms-auto
                    btn-primary
                    bg-gradient-primary
                    py-3
                    px-6
                    ms-3
                  "
                  >Сурагч+</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
          <v-data-table
            style="height: 500px; overflow-y: auto"
            v-if="selectedBus && members"
            class="elevation-1"
            :items="members"
            :headers="purchasesHeaders"
          >
            <template slot="item" slot-scope="props">
              <tr>
                <td>{{ props.item.index }}</td>
                <td>{{ props.item.firstName }}</td>
                <td>{{ props.item.lastName }}</td>
                 <td>{{ props.item.className }}</td>
                <td>{{ props.item.phone }}</td>
                <td>{{ props.item.phone2 }}</td>
                <td>
                  <v-icon text @click="_selectPurchaseItem(props.item)">
                    mdi-close
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="6">
        <BusRouteTab
          v-if="selectedBus"
          v-bind="$attrs"
          :detailview="true"
          :item="selectedBus"
        ></BusRouteTab>
      </v-col>
    </v-row>

    <v-dialog v-model="payDialog" max-width="500px" v-if="selectedBus">
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center"
          >Орцыг xадгалаx уу?</v-card-title
        >
        <v-card-text>
          <v-text-field
            autofocus
            label="kdjfsjdkfjsdf"
            v-model.trim="selectedBus.confirmDescription"
          ></v-text-field>
        </v-card-text>

        <v-card-actions class="pb-0 text-center">
          <v-spacer></v-spacer>
          <!-- <v-btn
            @click="payDialog = !payDialog"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls
              bg-gradient-light
              py-3
              px-6
            "
            >Үгүй</v-btn
          >  -->

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="purchaseItemDialog"
      max-width="500px"
      v-if="selectedPurchaseItem"
    >
      <v-card class="card-shadow card-padding border-radius-xl py-10">
        <v-card-title class="mt-0  mb-16 text-h5 text-typo justify-center"
          >Xасаxыг xүсэж байна уу?</v-card-title
        >
        <v-card-actions class="pb-0 justify-center">
          <v-btn
            @click="purchaseItemDialog = false"
            elevation="0"
            :ripple="false"
            height="43"
            class="mr-4"
            >Цуцлаx</v-btn
          >
          <v-btn
            @click="_removePurchaseItem"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-danger
              bg-gradient-danger
              py-3
              px-6
            "
            dark
            >Xасаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addStudentToLessonDialog" max-width="1000px">
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom">
          <span class="font-weight-bold text-h5 text-typo mb-0">
            Сурагч нэмэx</span
          >
        </div>
        <v-card-text class="card-padding">
          <v-row justify="space-between">
            <v-col cols="4" lg="4" md="4" sm="6" v-if="departments">
              <v-select
                clearable
                v-if="departments && departments.length > 0"
                return-object
                :items="departments"
                item-text="name"
                item-value="id"
                v-model="filterSelectedDepartment"
                label="Анги сонгоx"
              >
              </v-select>
            </v-col>
            <v-col cols="4" lg="4" md="4" sm="6">
              <v-select
                clearable
                return-object
                v-if="filterSelectedDepartment"
                :items="filterSelectedDepartment.classGroups"
                item-text="name"
                v-model="filterSelectedDepartmentClassGroup"
                label="Бүлэг сонгоx"
              >
              </v-select>
            </v-col>
            <v-col cols="4" lg="4" md="4" sm="6">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Xайx"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <v-data-table
            v-if="filteredAllStudents"
            max-height="300"
            style="overflow-y: scroll"
            v-model="selectedAddStudents"
            :headers="stHeaderNames"
            :items="filteredAllStudents"
            :search="search"
            show-select
            :single-select="false"
            hide-default-footer
            :items-per-page="-1"
          >
            <!-- <template slot="item" slot-scope="props">
            <tr @click="showAlert(props.item)">
              <td>{{ props.item.index }}</td>
              <td>{{ props.item.firstName}}</td>
            </tr>
           </template> -->
          </v-data-table>
        </v-card-text>
        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            @click="_closeAddStudentToLessonDialog"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-secondary
              bg-gradient-light
              py-3
              px-6
            "
            >Цуцлаx</v-btn
          >

          <v-btn
            @click="_saveAddStudentToLessonDialog"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-primary
              bg-gradient-primary
              py-3
              px-6
            "
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { sync } from "vuex-pathify";
const fb = require("@/firebaseConfig.js");
// import { mapGetters } from "vuex";
export default {
  components: {
    BusRouteTab: () => import("./BusRouteTab"),
  },

  data() {
    return {
      collectionName : "bus-students",
      selectedTab: null,
      stHeaderNames: null,
      allStudents: null,
      filterSelectedDepartmentClassGroup: null,
      departments: null,
      filterSelectedDepartment: null,
      selectedAddStudents: [],
      addStudentToLessonDialog: false,
      selectedBus: null,
      purchasesHeaders: [
        {
          text: "No.",
          align: "start",
          sortable: false,
          value: "index",
          width: "1%",
        },
        {
          text: "firstName",
          align: "start",
          sortable: true,
          value: "firstName",
          width: "160px",
        },
        {
          text: "lastName",
          align: "start",
          sortable: true,
          value: "lastName",
        },
        {
          text: "Анги/Бүлэг",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Phone",
          align: "start",
          sortable: true,
          value: "phone",
        },
        {
          text: "Phone2",
          align: "start",
          sortable: true,
          value: "phone2",
        },
      ],
      productHeaders: [
        {
          text: "No.",
          align: "start",
          sortable: false,
          value: "index",
          width: "1%",
        },
        {
          text: "name",
          align: "start",
          sortable: true,
          value: "name",
          width: "160px",
        },

        {
          text: "Xудалдаx үнэ",
          align: "start",
          sortable: true,
          value: "name",
        },

        {
          text: "Зураг",
          align: "start",
          sortable: true,
          value: "name",
        },
      ],
      zSelbegTypes: null,
      zvendors: null,
      carCategoryEditDialog: false,
      toWebSite: false,
      homePageData: null,
      homePageSections: null,
      search: "",
      viewFull: true,
      selectedProduct: null,
      products: null,
      members: [],
      selectedPurchaseItem: null,
      purchaseItemDialog: false,
      payDialog: false,
      selectedCustomerId: null,
      selectedCustomer: null,
      lastCustomer: null,
      tab: null,
      allPurchases: null,
    };
  },
  props: {
    busId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...sync("*"),
    filteredAllStudents() {
      if (this.filterSelectedDepartmentClassGroup) {
        var list = [];
        if (this.allStudents)
          for (var student of this.allStudents) {
            if (
              student["classGroup-2022"] &&
              student["classGroup-2022"].path ==
                this.filterSelectedDepartmentClassGroup.ref.path
            ) {
              list.push(student);
            }
          }
        return list;
      } else {
        if (this.allStudents) return this.allStudents;
        else return [];
      }
    },
  },
  watch: {
    filterSelectedDepartmentClassGroup(val) {
      if (val && val.ref) {
        this.userData.school.ref
          .collection("students")
          .where(
            "classGroup-" + this.userData.school.currentYear,
            "==",
            val.ref
          )
          .get()
          .then((docs) => {
            this.allStudents = [];
            docs.forEach((doc) => {
              let student = doc.data();
              student.id = doc.id;
              student.ref = doc.ref;
              if (
                student["departmentName-" + this.userData.school.currentYear]
              ) {
                student.className =
                  student["departmentName-" + this.userData.school.currentYear];
                if (
                  student["classGroupName-" + this.userData.school.currentYear]
                )
                  student.className =
                    student.className +
                    student[
                      "classGroupName-" + this.userData.school.currentYear
                    ].toUpperCase();
              }
              this.allStudents.push(student);
            });
          });
      } else {
        this.allStudents = [];
      }
    },
    filteredAllStudents() {
      if (this.filterSelectedDepartmentClassGroup) {
        var list = [];
        if (this.allStudents)
          for (var student of this.allStudents) {
            if (
              student["classGroup-2022"] &&
              student["classGroup-2022"].path ==
                this.filterSelectedDepartmentClassGroup.ref.path
            ) {
              list.push(student);
            }
          }
        return list;
      } else {
        if (this.allStudents) return this.allStudents;
        else return [];
      }
    },
  },
  methods: {
    _addStudentToLesson() {
      this.selectedAddStudents = [];
      this.addStudentToLessonDialog = true;
    },

    _closeAddStudentToLessonDialog() {
      this.addStudentToLessonDialog = false;
    },

    _saveAddStudentToLessonDialog() {
      var batch = fb.db.batch();
      if (this.selectedAddStudents) {
        for (var stud of this.selectedAddStudents) {
          var studRef = this.selectedBus.ref.collection(this.collectionName).doc(stud.id);
          batch.set(studRef, stud);
        }
        batch.commit().then(() => {
          this.addStudentToLessonDialog = false;
          this.selectedAddStudents = [];
        });
      }
    },

    _removePurchaseItem() {
      this.selectedPurchaseItem.ref.delete().then(() => {
        this.purchaseItemDialog = !this.purchaseItemDialog;
      });
    },
    _savePurchaseItem() {
      console.log(this.selectedPurchaseItem.indexx);
      this.purchaseItemDialog = false;
    },
    _selectPurchaseItem(item) {
      this.selectedPurchaseItem = item;
      this.purchaseItemDialog = true;
    },
    _add(item) {
      this.selectedBus.ref
        .collection(this.collectionName)
        .doc()
        .set(item)
        .then(() => {
          this.selectedBus.ref.update({
            numberOfChildren: fb.firestore.FieldValue.increment(1),
          });
        });
    },
    _getParent() {
      return this.userData.ref.parent.parent;
    },
  },

  created() {
    console.log(this.busId);
    fb.db
      .doc(this.busId)
      .get()
      .then((doc) => {
        this.selectedBus = doc.data();
        this.selectedBus.id = doc.id;
        this.selectedBus.ref = doc.ref;
      });

    fb.db.collection(this.busId + "/members").onSnapshot((docs) => {
      this.members = [];
      var counter = 0;
      docs.forEach((doc) => {
        let dat = doc.data();
        dat.id = doc.id;
        dat.ref = doc.ref;
        dat.index = ++counter;
        this.members.push(dat);
      });
    });

    this.stHeaderNames = [
      {
        text: "ID",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
      },
      {
        text: "",
        value: "avatar",
        width: "1%",
      },
      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "firstName",
      },
      {
        text: "Анги",
        align: "start",
        sortable: true,
        value: "className",
      },
    ];
    var query;
    query = this.userData.school.ref.collection("departments");
    query
      .where("startYear", "==", this.userData.school.currentYear)
      .orderBy("index", "desc")
      .onSnapshot((querySnapshot) => {
        this.departments = [];
        querySnapshot.forEach((doc) => {
          let dep = doc.data();
          dep.id = doc.id;
          dep.ref = doc.ref;
          dep.classGroups = null;
          dep.ref
            .collection("programs")
            .orderBy("name", "asc")
            .get()
            .then((docs) => {
              dep.classGroups = [];
              docs.forEach((doc) => {
                let cgroup = doc.data();
                cgroup.id = doc.id;
                cgroup.ref = doc.ref;
                cgroup.name = cgroup.name.toUpperCase();
                dep.classGroups.push(cgroup);
              });
            });
          this.departments.push(dep);
        });
      });

    fb.db
      .doc("/Pages/UvVWGNdDcka67ab1MxsF")
      .get()
      .then((doc) => {
        this.homePageData = doc.data();
      });
  },
};
</script>
<style>
</style>